import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState({
      userId: (state) => state.account.userId,
      isDistributor: (state) => state.account.isDistributor,
      isAdmin: (state) => state.account.isAdmin,
      isPtAdmin: (state) => state.account.isPtAdmin,
      isDistributorAdmin: (state) => state.account.isDistributorAdmin,
      isDistributorAgent: (state) => state.account.isDistributorAgent,
      isEnduser: (state) => state.account.isEnduser,
    })
  }
}